import { useEffect } from "react";
export function useCloseDropdown(ref, handler, enabled = true) {
    useEffect(() => {
        if (!enabled)
            return;
        const handleMouseDown = (event) => {
            var _a;
            if (!((_a = ref.current) === null || _a === void 0 ? void 0 : _a.contains(event.target))) {
                handler();
            }
        };
        function handleKeyDown(event) {
            if (event.key === "Escape") {
                handler();
            }
        }
        document.addEventListener("mousedown", handleMouseDown);
        document.addEventListener("keydown", handleKeyDown);
        return () => {
            document.removeEventListener("mousedown", handleMouseDown);
            document.removeEventListener("keydown", handleKeyDown);
        };
    }, [ref, handler, enabled]);
}

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { SIGNUP_URL } from "./constants";
import { CompanyIcon } from "./icons/CompanyIcon";
import { SearchIcon } from "./icons/SearchIcon";
import { SolutionIcon } from "./icons/SolutionIcon";
import { TimeIcon } from "./icons/TimeIcon";
const Popup = ({ className, children }) => {
    return (_jsxs("div", { className: className, children: [_jsxs("div", { className: "flex flex-col gap-2 items-center", children: [_jsx(CompanyIcon, { className: "w-24 h-auto" }), children, _jsx("span", { children: "Sign up as a buyer and enjoy below benefits" }), _jsx("button", { className: "px-4 py-2 m-4 font-semibold text-white bg-blue-500 rounded-lg hover:bg-blue-700", onClick: () => (window.location.href = SIGNUP_URL), children: "Sign up for free" })] }), _jsxs("div", { className: "flex flex-col md:flex-row gap-1 items-start", children: [_jsx("div", { className: "w-20 flex justify-center", children: _jsx(SearchIcon, { className: "w-20" }) }), _jsxs("div", { className: "flex flex-col flex-grow", children: [_jsx("h3", { className: "font-semibold mt-1", children: "Browse 100,000+ vetted suppliers" }), _jsx("p", { className: "text-gray-600 mt-2 text-balance", children: "Find the right suppliers by filtering for location, certifications, packaging, company size and more!" })] }), _jsx("div", { className: "w-20 flex justify-center", children: _jsx(TimeIcon, { className: "w-20" }) }), _jsxs("div", { className: "flex flex-col flex-grow", children: [_jsx("h3", { className: "font-semibold mt-1", children: "Save countless hours" }), _jsx("p", { className: "text-gray-600 mt-2 text-balance", children: "No more Googling or cold-emailing, connect directly with the relevant manufacturers in minutes!" })] }), _jsx("div", { className: "w-20 flex justify-center", children: _jsx(SolutionIcon, { className: "w-20" }) }), _jsxs("div", { className: "flex flex-col flex-grow", children: [_jsx("h3", { className: "font-semibold mt-1", children: "Launch products faster" }), _jsx("p", { className: "text-gray-600 mt-2 text-balance", children: "Drive the process easily through our tools to save time and launch your products faster!" })] })] })] }));
};
export default Popup;

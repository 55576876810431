import { Children, isValidElement } from "react";
export function cx(...classNames) {
    return classNames.filter(Boolean).join(" ");
}
export function capitalize(value) {
    if (typeof value !== "string")
        return "";
    return value.charAt(0).toUpperCase() + value.slice(1);
}
export function getFirstChildPropValue(children, propNameCb) {
    let propValue = undefined;
    Children.forEach(children, (element) => {
        if (!isValidElement(element))
            return;
        const propName = propNameCb(element.props);
        if (propName in element.props) {
            propValue = element.props[propName];
            return;
        }
    });
    return propValue;
}

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from "react";
import { ModalCloseIcon } from "./icons/ModalCloseIcon";
const Modal = ({ children, isOpen, closeModal }) => {
    // Close modal when the escape key is pressed
    useEffect(() => {
        const handleEscKey = (event) => {
            if (event.key === "Escape") {
                closeModal();
            }
        };
        if (isOpen) {
            document.addEventListener("keydown", handleEscKey);
        }
        return () => {
            document.removeEventListener("keydown", handleEscKey);
        };
    }, [isOpen]);
    if (!isOpen) {
        return null;
    }
    return (_jsx("div", { className: "fixed inset-0 z-50 flex flex-grow items-center justify-center m-0 p-0 bg-black bg-opacity-50", "aria-modal": "true", role: "dialog", children: _jsxs("div", { className: "relative w-full p-8 bg-white rounded-lg shadow-lg max-w-[98%] max-h-[98%] overflow-auto", children: [_jsx("div", { className: "flex justify-start", children: _jsx("button", { onClick: (event) => {
                            event.stopPropagation();
                            closeModal();
                        }, className: "absolute top-3 left-3", "aria-label": "Close modal", children: _jsx(ModalCloseIcon, { className: "w-6 h-6" }) }) }), _jsx("div", { className: "mt-3 w-auto h-auto", children: children })] }) }));
};
export default Modal;

var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { forwardRef, } from "react";
import { cx } from "./utils";
export const Panel = forwardRef((_a, ref) => {
    var { children, header, footer, className, classNames = {} } = _a, props = __rest(_a, ["children", "header", "footer", "className", "classNames"]);
    return (_jsxs("div", Object.assign({}, props, { className: cx("ais-Panel", classNames.root, className), ref: ref, children: [header && (_jsx("div", { className: cx("ais-Panel-header", classNames.header), children: header })), _jsx("div", { className: cx("ais-Panel-body", classNames.body), children: children }), footer && (_jsx("div", { className: cx("ais-Panel-footer", classNames.footer), children: footer }))] })));
});

import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import chroma from "chroma-js";
import { Highlight, Snippet } from "react-instantsearch";
import ButtonModal from "./ButtonModal";
import Modal from "./Modal";
import Popup from "./Popup";
import { IndustryIcon } from "./icons/IndustryIcon";
const getRandomColor = () => {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
};
const getInitialsWithDots = (name) => {
    return (name
        .split(" ") // split the name into words
        .filter((word) => /^[a-zA-Z]/.test(word)) // ensure the word starts with a letter
        .map((word) => word.charAt(0).toUpperCase()) // take the first letter and uppercase it
        .slice(0, 7) // take the first sevel letters
        .join(".") + "." // loin with dots and add the final dot
    );
};
const Labels = ({ label, tags }) => {
    return (_jsx(_Fragment, { children: tags && tags.length > 0 && (_jsxs("div", { className: "flex items-center flex-wrap mb-4", children: [_jsxs("span", { className: "font-semibold text-sm mr-2", children: [label, ":"] }), tags.map((tag) => (_jsx("span", { className: "text-xs bg-gray-100 text-gray-800 rounded-full px-3 py-1 m-1", children: tag }, tag)))] })) }));
};
const PopupContact = ({ name }) => {
    return (_jsx(Popup, { children: _jsxs("span", { className: "text-2xl text-blue-600 font-bold text-center", children: ["To contact ", _jsx("span", { className: "text-black p-1", children: name }), _jsx("br", { className: "hidden md:block" }), "you need to sign up to Torg"] }) }));
};
const PopupProfile = ({ name }) => {
    return (_jsx(Popup, { children: _jsxs("span", { className: "text-2xl text-blue-600 font-bold text-center", children: ["To view the profile of", _jsx("span", { className: "text-black p-1", children: name }), _jsx("br", { className: "hidden md:block" }), " you need to sign up to Torg"] }) }));
};
const SupplierCard = ({ hit }) => {
    const [colors, setColors] = useState({});
    const [isProfileModalOpen, setIsProfileModalOpen] = useState(false);
    const openProfileModal = () => setIsProfileModalOpen(true);
    const closeProfileModal = () => setIsProfileModalOpen(false);
    const objectID = hit.objectID;
    const name = hit.name;
    const description = hit.description;
    const industry = hit.industry_1;
    const country = hit.country;
    const certifications = hit.certifications;
    const businessTags = hit.business_tags;
    const tradeShows = hit.trade_shows;
    const trendsTags = hit.trends_tags;
    const packaging = hit.packaging;
    // filter out invalid urls that point to js files
    let logoUrl = hit.logo_url;
    if (logoUrl && logoUrl.endsWith(".js")) {
        logoUrl = "";
    }
    // create a random color once
    useEffect(() => {
        if (!logoUrl && !colors[objectID]) {
            setColors((prevColors) => (Object.assign(Object.assign({}, prevColors), { [hit.objectID]: getRandomColor() })));
        }
    }, [hit.objectID, colors]);
    const backgroundColor = colors[objectID];
    const textColor = backgroundColor && chroma(backgroundColor).luminance() > 0.5
        ? "#000000"
        : "#FFFFFF";
    return (_jsxs(_Fragment, { children: [_jsx(Modal, { isOpen: isProfileModalOpen, closeModal: closeProfileModal, children: _jsx(PopupProfile, { name: name }) }), _jsxs("div", { className: "supplier-card relative w-full flex flex-col md:flex-row items-start space-x-6 rounded-2xl p-4 m-2 max-w-screen-xl cursor-pointer transition-all duration-300 border border-transparent hover:border-gray-400 hover:shadow-lg", onClick: (event) => {
                    event.stopPropagation();
                    openProfileModal();
                }, children: [logoUrl ? (_jsx("div", { className: "w-full md:w-40 h-40 bg-center bg-contain aspect-square bg-no-repeat self-center flex-shrink-0 rounded-lg", style: { backgroundImage: `url(${logoUrl})` }, "aria-label": name || "Logo" })) : (_jsx("div", { className: "flex items-center justify-center w-full md:w-40 h-40 aspect-square flex-shrink-0 rounded-lg", style: {
                            backgroundColor: backgroundColor || "#CCC",
                        }, children: _jsx("span", { className: "text-2xl font-bold", style: { color: textColor }, children: name ? getInitialsWithDots(name) : "" }) })), _jsxs("div", { className: "flex-grow mt-4 md:mt-0", children: [_jsxs("div", { className: "flex flex-col mb-2 md:flex-row relative", children: [name && (_jsx(Highlight, { className: "text-2xl font-bold flex-grow break-words pr-0 md:pr-56", attribute: "name", hit: hit })), _jsxs("div", { className: "flex relative md:absolute md:top-0 md:right-0 flex-wrap md:flex-nowrap", children: [_jsx(ButtonModal, { className: "m-1 md:mt-0 flex-none", buttonText: "View Profile", children: _jsx(PopupProfile, { name: name }) }), _jsx(ButtonModal, { className: "m-1 md:mt-0 flex-none", buttonText: "Contact", children: _jsx(PopupContact, { name: name }) })] })] }), industry && industry.length > 0 && (_jsxs("p", { className: "flex items-center md:items-end text-sm text-gray-600 mb-2", children: [_jsx(IndustryIcon, { className: "pr-1 h-8 w-8" }), _jsx("span", { className: "font-semibold pr-1", children: "Industry:" }), _jsx(Highlight, { className: "flex-1", attribute: "industry_1", hit: hit })] })), description && (_jsx("p", { className: "text-sm text-gray-700 mb-4", children: _jsx(Snippet, { attribute: "description", hit: hit }) })), packaging && packaging.length > 0 && (_jsxs("p", { className: "text-sm text-gray-600 mb-2", children: [_jsx("span", { className: "font-semibold pr-1", children: "Packaging:" }), _jsx(Highlight, { attribute: "packaging", hit: hit })] })), country && (_jsxs("p", { className: "text-sm text-gray-600 mb-2", children: [_jsx("span", { className: "font-semibold pr-1", children: "Country:" }), _jsx(Highlight, { attribute: "country", hit: hit })] })), _jsx(Labels, { label: "Type", tags: businessTags }), _jsx(Labels, { label: "Trade Shows", tags: tradeShows }), _jsx(Labels, { label: "Trends", tags: trendsTags }), _jsx(Labels, { label: "Certificates", tags: certifications })] })] })] }));
};
export default SupplierCard;

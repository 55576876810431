import { useEffect, useState } from "react";
export function useMediaQuery(query) {
    const getMatches = (query) => {
        // Prevents SSR issues
        if (typeof window !== "undefined") {
            return window.matchMedia(query).matches;
        }
        return false;
    };
    const [matches, setMatches] = useState(getMatches(query));
    function handleChange() {
        setMatches(getMatches(query));
    }
    useEffect(() => {
        const matchMedia = window.matchMedia(query);
        // Triggered at the first client-side load and if query changes
        handleChange();
        // Listen matchMedia
        matchMedia.addEventListener("change", handleChange);
        return () => {
            matchMedia.removeEventListener("change", handleChange);
        };
    }, [query]);
    return matches;
}

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import Modal from "./Modal";
const ButtonModal = ({ children, buttonText, className, }) => {
    const [isOpen, setIsOpen] = useState(false);
    const openModal = () => setIsOpen(true);
    const closeModal = () => setIsOpen(false);
    return (_jsxs("div", { className: className, children: [_jsx("button", { onClick: (event) => {
                    event.stopPropagation();
                    openModal();
                }, className: "px-4 py-2 font-semibold text-white bg-blue-500 rounded-lg hover:bg-blue-700", children: buttonText }), _jsx(Modal, { isOpen: isOpen, closeModal: closeModal, children: children })] }));
};
export default ButtonModal;
